.MetaNavigation__list {
    display: flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--spacing--uno));
    padding: 0;
    list-style: none;
}

.MetaNavigation__item {
    width: 100%;
}

.MetaNavigation__item--ability-watch .MetaNavigation__icon svg {
    height: var(--spacing--tera);
}

.MetaNavigation__item--imprint {
    margin-top: var(--spacing--uno);
}

.MetaNavigation__item--a11y {
    margin-bottom: var(--spacing--uno);
}

.MetaNavigation__item--social-media {
    width: auto;
}

.MetaNavigation__link {
    display: inline-flex;
    padding: var(--spacing--uno);
    text-decoration: none;

    &[aria-current = page] {
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &:focus {
        outline-color: var(--color--white);
    }

    & .Logo {
        font-size: var(--font-size--mega);
    }
}

.MetaNavigation__icon {
    display: flex;

    svg {
        width: auto;
        height: calc(var(--spacing--kilo) - var(--spacing--nano));
    }
}

@media(--breakpoint--xx-large) {

    .MetaNavigation__list {
        align-items: center;
        justify-content: space-between;
    }

    .MetaNavigation__item {
        width: auto;
        margin: 0;
    }

    .MetaNavigation__item--ability-watch .MetaNavigation__icon svg {
        height: calc(var(--spacing--giga) + var(--spacing--micro));
    }
}
