.Header {
    background-color: var(--color--beige-light);
}

.Header__wrapper {
    display: flex;
}

@media(--breakpoint--xx-large--max) {

    .Header__wrapper {
        flex-direction: column;
        position: relative;
    }
}

@media(--breakpoint--xx-large) {

    .Header {
        position: relative;
    }

    .Header__wrapper {
        justify-content: stretch;
        padding: var(--spacing--milli) var(--spacing--uno);
        flex-direction: column-reverse;
        max-width: var(--max-width);
        margin: 0 auto;
    }
}


@media(--breakpoint--xxx-large) {
    .Header__wrapper {
        max-width: var(--max-width);
        margin: 0 auto;
    }
}
