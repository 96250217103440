.ContentAssistance__links {
    margin-top: var(--spacing--kilo);

    & .CallToAction + .CallToAction {
        margin-left: var(--spacing--mega);
    }
}

.ContentSpkbl--assistance {
    width: 100%;
}
