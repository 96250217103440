.ContentMap__heading {
    font-size: var(--font-size--giga);
}

.ContentMap__content + .ContentNumbers {
    margin-top: var(--spacing--giga);
}

@media(--breakpoint--large) {

    .ContentMap__heading {
        font-size: var(--font-size--tera);
    }

    .ContentMap__content {
        max-width: var(--map--max-width);
        margin: var(--spacing--giga) auto 0 auto;
    }
    .ContentMap__content + .ContentNumbers {
        margin-top: var(--spacing--tera);
    }
}
