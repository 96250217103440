h1,
h2,
h3,
h4,
h5,
h6,
.Heading,
.Heading--large,
.Heading--medium,
.Heading--small {
    margin-top: 0;
    font-family: 'Crimson Pro';
    font-weight: var(--font-weight--bold);
    text-transform: uppercase;
    hyphens: auto;
    line-height: var(--line-height--kilo);
}

.Heading {

    h1&,
    &.Heading--large {
        font-size: var(--font-size--giga);
    }

    h2&,
    &.Heading--medium {
        font-size: var(--font-size--mega);
    }

    h3&,
    &.Heading--small {
        font-family: var(--font-family--roboto), sans-serif;
        font-size: var(--font-size--kilo);
        text-transform: none;
        max-width: var(--content-max-width);
        margin: 0 auto;
    }
}

@media(--breakpoint--large) {

    .Heading {

        h1&,
        &.Heading--large {
            font-size: var(--font-size--tera);
        }

        h2&,
        &.Heading--medium {
            font-size: var(--font-size--giga);
        }

        h3&,
        &.Heading--small {
            font-size: var(--font-size--mega);
        }
    }
}
