.ContentType--image {
    max-width: var(--content-max-width);
    margin: 0 auto;

    & img {
        display: block;
    }

    & + .ContentType--link {
        margin-top: var(--spacing--kilo);
    }

    .ContentType--link + & {
        margin-top: var(--spacing--mega);
    }
}

@media(--breakpoint--large) {
    .ContentType--image {

        & + .ContentType--link {
            margin-top: var(--spacing--mega);
        }

        .ContentType--link + & {
            margin-top: var(--spacing--tera);
        }
    }

}
