
.Table { /* table */
    width: 100%;
    border-collapse: collapse;
}

.Table__head { /* thead */
    background-color: var(--color--beige-dark);
    border: 1px solid var(--color--beige-border);
}

.Table__cell--head { /* th */
    text-align: left;
}

.Table__sort-button {
    padding: var(--spacing--uno);
    background-color: transparent;
    border: none;
    font-size: var(--font-size--uno);
    font-weight: var(--font-weight--bold);
    cursor: pointer;
}

.Table__cell-icon { /* icon */
    display: inline-block;
    margin-left: var(--spacing--micro);
    transition: transform 0.2s;
    transform: rotate(270deg);

    & svg {
        width: var(--spacing--milli);
        height: var(--spacing--milli);
    }

    [aria-sort = "descending"] & {
        transform: rotate(360deg);
    }

    [aria-sort = "ascending"] & {
        transform: rotate(180deg);
    }
}

.Table__cell--head[aria-sort="ascending"],
.Table__cell--head[aria-sort="descending"] {
    & span:first-child {
        border-bottom: 2px solid var(--color--black);
    }
}

.Table__row--body { /* tr */
    border: 1px solid var(--color--beige-border);
}

.Table__cell--body { /* td */
    padding: var(--spacing--uno);
    text-align: left;

    &:first-of-type {
        font-weight: var(--font-weight--bold);
    }
}

.Table__cell--name { /* td Name */
    & a:hover {
        text-decoration: none;
    }
}

.Table--assistances {
    & .Table__head {
        display: none;
    }
}

@media(--breakpoint--medium--max) {
    .Table__head { /* thead */
        display: flex;
        flex-wrap: wrap;
        border: none;
        margin-bottom: var(--spacing--milli);
        padding: var(--spacing--milli);
    }

    .Table__cell.Table__cell--head {
        margin-right: 0;
        padding: 0;
        display: inline-block;

        & .Table__cell-icon {
            margin-left: 0;
        }
    }

    .Table__body { /* tbody */
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
    }

    .Table__row--body { /* tr */
        display: grid;
        grid-template-columns: max-content auto;
        padding: var(--spacing--micro);
        border-color: var(--color--beige-border);

        & + .Table__row--body {
            margin-top: var(--spacing--milli);
        }
    }

    .Table__cell {
        padding: var(--spacing--nano);
    }

    .Table__cell--name,
    .Table__cell--state,
    .Table__cell--year {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .Table--cases {
        .Table__cell--number {
            padding: var(--spacing--micro);
            padding-top: 7px;
        }
    }
}
