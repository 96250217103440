body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Roboto';
    font-size: var(--font-size--uno);
    line-height: var(--line-height--mega);
}

a {
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}

*:focus {
    outline: 1px solid var(--color--red);
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.reset-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

svg {
    width: 100%;
    height: auto;
    fill: currentColor;
}

img {
    width: 100%;
}

blockquote {
    margin: 0;
}

html {
    position: relative;
    z-index: 0;

    @media (--breakpoint--small) {
        z-index: 100;
    }
    @media (--breakpoint--medium) {
        z-index: 200;
    }
    @media (--breakpoint--large) {
        z-index: 300;
    }
    @media (--breakpoint--x-large) {
        z-index: 400;
    }
    @media (--breakpoint--xx-large) {
        z-index: 500;
    }
}
