.ContentAssistances {
    width: 100%;
}

.Assistances + .Assistances {
    margin-top: var(--spacing--micro);
}

.Assistances__summary {
    background-color: var(--color--beige-brown);
    font-weight: var(--font-weight--bold);
    padding: 1.25rem var(--spacing--uno);

    &::marker {
        content: '';
        /* for Safari */
        color: transparent;
        font-size: 0;
    }
}

.Assistances__summary-icon {
    display: inline-block;
    margin-left: var(--spacing--uno);
    transition: .2s;
    transform: rotate(0deg);

    [open] & {
        transform: rotate(180deg);
    }

    & svg {
        width: var(--spacing--uno);
    }
}

.Assistances__content {
    padding: var(--spacing--uno) 0;
}

@media(--breakpoint--large) {
    .Assistances__summary {
        padding: 1.25rem var(--spacing--giga);
    }
}
