.ContentImage svg {
    display: block;
    max-width: var(--image--max-width--mobile);
}

.ContentImage__content {
    margin-top: var(--spacing--mega);
}

.ContentImage__text {
    & p > strong.only-child {
        font-size: var(--font-size--kilo);
    }
}

.ContentImage__link {
    margin-top: var(--spacing--kilo);
}

@media(--breakpoint--medium) {

    .ContentImage {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        & svg {
            max-width: var(--image--max-width--tablet);
            padding-top: var(--spacing--micro);
        }
    }

    .ContentImage svg,
    .ContentImage__content {
        width: 50%;
    }

    .ContentImage svg {
        padding-right: var(--spacing--mega);
    }

    .ContentImage__content {
        margin-top: 0;
        padding-left: var(--spacing--mega);
    }
}

@media(--breakpoint--large) {

    .ContentImage {
        margin: 0 var(--spacing--exa);

        & svg {
            max-width: var(--image--max-width--desktop);
            padding-top: var(--spacing--uno);
        }
    }
}
