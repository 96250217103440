/* Speakable Text-To-Speech player 0.4.1-pre | https://github.com/tollwerk/speakable */
.spkbl-player > * {
    background-color: #eee;
    vertical-align: middle;
    border: 1px solid #000;
    height: 1.6rem
}

.spkbl-player > *:focus {
    outline: 2px solid #000
}

.spkbl-player--inactive .spkbl-ctrl:not(.spkbl-ctrl--play), .spkbl-player--active .spkbl-ctrl--play {
    display: none
}

.spkbl-ctrl--play::before {
    content: '▶️ '
}

.spkbl-ctrl--stop::before {
    content: '⏹️ '
}

.spkbl-ctrl--reset::before {
    content: '⏮️️ '
}

.spkbl-ctrl--pause::before {
    content: '⏸️️ '
}

.spkbl-ctrl--pause[aria-pressed="true"] {
    color: #666;
    background-color: #bbb
}

.spkbl-ctrl--pause[aria-pressed="true"]::before {
    content: '▶️ '
}

.spkbl-ctrl--progress {
    width: 5em;
    appearance: none;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
    position: relative;
    z-index: -1;
    padding: 2px
}

.spkbl-ctrl--progress::-webkit-progress-bar {
    background-color: #919191
}

.spkbl-ctrl--progress::-moz-progress-bar {
    background-color: #000
}

.spkbl-ctrl--progress::-webkit-progress-value {
    background-color: #000
}
