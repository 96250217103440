h1.ContentIntro__heading {
    margin: 0;
    color: var(--color--red);
    font-size: var(--font-size--tera);
    line-height: var(--line-height--kilo);
    hyphens: none;
}

.ContentIntro__text {
    margin-bottom: var(--spacing--kilo);

    & p > strong.only-child {
        font-size: var(--font-size--kilo);
    }
}

@media(--breakpoint--large) {

    .ContentIntro {
        display: flex;
        justify-content: space-between;
        margin-right: var(--spacing--exa);
    }

    .ContentIntro__headline,
    .ContentIntro__content {
        width: 50%;
    }

    .ContentIntro__content {
        padding-left: var(--spacing--peta);
    }

    h1.ContentIntro__heading {
        font-size: var(--font-size--peta);
    }
}

@media(--breakpoint--x-large) {

    .ContentIntro__content {
        padding-left: var(--spacing--yotta);
    }
}
