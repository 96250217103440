.Logo {
    display: flex;
    align-items: center;
}

.Logo__icon {
    flex-shrink: 0;
    max-width: 100%;
}

.Logo__icon svg {
    max-width: 300px;
    flex-shrink: 0;
}

.Logo__text {
    margin-left: var(--spacing--nano);
    font-family: 'Crimson Pro';
    font-weight: var(--font-weight--bold);
}

@media(--breakpoint--medium--max) {
    .Logo {
        font-size: 1.2rem;
    }
}

@media(--breakpoint--medium) {

    .Logo {
        font-size: var(--font-size--mega);
    }
}
