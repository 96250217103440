/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 27, 2022 */

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
    url('../fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-italic-webfont.woff2') format('woff2'),
    url('../fonts/roboto-italic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
    url('../fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Crimson Pro';
    src: url('../fonts/crimsonpro-variablefont_wght-webfont.woff2') format('woff2'),
    url('../fonts/crimsonpro-variablefont_wght-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
