.Frame {
    flex-grow: 1;
}

.Frame__wrapper {
    padding: var(--spacing--giga) var(--spacing--kilo);
}

.Frame--color-beige-light {
    background-color: var(--color--beige-light);
}

.Frame--color-beige {
    background-color: var(--color--beige);
}

@media(--breakpoint--large) {

    .Frame__wrapper {
        display: flex;
        max-width: var(--max-width);
        margin: 0 auto;
        justify-content: stretch;
        align-items: center;
    }
}
