.MainNavigation {
    padding: var(--spacing--uno) calc(var(--spacing--mega) - var(--spacing--micro));
    border-top: 1px solid var(--color--beige-border);
    box-sizing: border-box;

    & * {
        box-sizing: border-box;
    }

    #menu:target &,
    &.MainNavigation--visible {
        display: block;
    }
}

.MainNavigation__list {
    list-style: none;
    margin: 0;

    .js & {
        display: none;
    }
}

.MainNavigation__item--home {
    display: none;
}

.MainNavigation__homelink {
    text-decoration: none;
    display: block;
}

.MainNavigation__link {
    display: inline-block;
    text-decoration: none;
    padding: var(--spacing--milli) var(--spacing--micro);
    font-weight: var(--font-weight--bold);

    &[aria-current = page] {
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
        color: var(--color--red-dark);
    }

    &:focus {
        outline-color: var(--color--red-dark);
    }

    &:active {
        outline: none;
        text-decoration: none;
    }
}

/*
 * Submenu logic
 */
.MainNavigation__link--button {
    display: flex;
    align-items: center;
    column-gap: var(--spacing--micro);
}

.MainNavigation__icon {
    display: flex;

    & svg {
        width: var(--spacing--uno);
        height: auto;
        transform: rotate(0deg);
        transition: transform 0.2s;

        .MainNavigation__link--button[aria-expanded="true"] & {
            transform: rotate(180deg);
        }
    }
}

.MainNavigation__top {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
}

.MainNavigation__toggle {
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: var(--color-black);
    padding: 0 var(--spacing--micro);

    &[aria-expanded = false] {
        & .MainNavigation__toggle-icon--close {
            display: none;
        }
    }

    &[aria-expanded = true] {
        & .MainNavigation__toggle-icon--open {
            display: none;
        }
    }
}

.MainNavigation__toggle-icon {
    display: flex;
    margin-left: var(--spacing--micro);

    & svg {
        width: var(--spacing--uno);
        height: auto;
    }
}

.no-js {
    & .MainNavigation__icon {
        display: none;
    }

    & .MainNavigation__toggle {
        display: none;
    }
}

@media(--breakpoint--xx-large--max) {

    .MainNavigation {
        padding-left: 0;
        padding-right: 0;
    }

    .MainNavigation__top {
        padding-left: var(--spacing--kilo);
        padding-right: var(--spacing--kilo);
    }

    .MainNavigation__list.MainNavigation__list--active {
        display: block;
    }

    .MainNavigation__list {
        margin-top: var(--spacing--uno);
        width: 100%;
        background-color: var(--color--white);
        padding: 0;
    }

    .MainNavigation__link {
        margin: 0 var(--spacing--mega);
    }

    .MainNavigation__item:last-child .MainNavigation__sublist {
        margin-bottom: -1rem;
    }
}


@media(--breakpoint--xx-large) {

    .MainNavigation {
        display: block;
        display: flex;
        flex-grow: 1;
        border: none;
        padding: 0;
        align-self: flex-start;
        width: 100%;
        max-width: var(--max-width);
    }

    .MainNavigation__top {
        margin-right: auto;
    }

    .MainNavigation__list.MainNavigation__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .MainNavigation__item--home {
        display: block;
        margin: 0 auto 0 0;
    }

    .MainNavigation__link--button {
        position: relative;
    }

    .MainNavigation__link--button:hover::after {
        content: '';
        position: absolute;
        background-color: transparent;
        width: 100%;
        height: 5rem;
        top: 100%;
        left: 0;
        z-index: 1;
    }

    .MainNavigation__sublist {
        z-index: 2;
    }

    .MainNavigation__item {
        margin: 0 var(--spacing--uno);
    }

    .MainNavigation__item--home {
        margin: 0 auto 0 0;
    }

    .no-js {
        & .MainNavigation {
            flex-direction: column;
        }

        & .MainNavigation__item {
            width: 100%;
        }
    }


    .MainNavigation__item:last-child {
        margin-right: calc(0 - var(--spacing--micro));
    }
}

