.Trigger {
    padding: var(--spacing--uno) var(--spacing--uno);
    margin-left: calc(0 - var(--spacing--uno));
    margin-right: calc(0 - var(--spacing--uno));
    border: 2px solid var(--color--red-dark);
    font-style: italic;
}

.Trigger__warning {
    color: var(--color--red-dark);
    font-weight: var(--font-weight--bold);
}



