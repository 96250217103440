.Footer {
    margin-top: auto;
    padding: var(--spacing--mega) var(--spacing--uno) var(--spacing--giga);
    color: var(--color--white);
    background-color: var(--color--black);
}

.Footer__wrapper {
    padding: 0 calc(var(--spacing--mega) - var(--spacing--uno));
}

@media(--breakpoint--large) {

    .Footer {
        padding: var(--spacing--giga) var(--spacing--uno);
    }

    .Footer__wrapper {
        max-width: var(--max-width);
        margin: 0 auto;
    }
}
