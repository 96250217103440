.ContentNumbers {
    margin-bottom: calc(var(--spacing--kilo) * -1);
}

.ContentNumbers__container {
    margin-bottom: var(--spacing--kilo);
    font-weight: var(--font-weight--bold);
    max-width: 300px;
}

.ContentNumbers__numbers {
    padding-left: 0;
    list-style-type: none;
    width: 100%;
    box-sizing: border-box;
}

.ContentNumbers__number {
    margin-bottom: var(--spacing--milli);
    font-family: 'Crimson Pro';
    font-size: var(--font-size--tera);
    font-weight: var(--font-weight--bold);
    color: var(--color--red);
}

@media(--breakpoint--medium) {
    .ContentNumbers {
        width: 100%;
    }

    .ContentNumbers__numbers {
        margin: 0 calc(var(--spacing--uno) * -1);
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .ContentNumbers__container {
        width: calc(50% - var(--spacing--uno) * 2);
        padding: 0 var(--spacing--uno);
    }
}

@media(--breakpoint--large) {

    .ContentNumbers {
        margin: 0 var(--spacing--exa);
        width: calc(100% - var(--spacing--exa) * 2);
    }

    .ContentNumbers__numbers {
        padding: 0 var(--spacing--exa);
    }

    .ContentNumbers__container {
        width: calc(50% - var(--spacing--mega) * 2);
        padding: 0 var(--spacing--mega);
    }

    .ContentNumbers__number {
        font-size: var(--font-size--peta);
    }

    .ContentNumbers__source {
        margin: var(--spacing--giga) 7rem 0;
    }
}
