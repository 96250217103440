.CallToAction {
    display: inline-flex;
    align-items: center;
    padding: var(--spacing--uno) var(--spacing--giga);
    color: var(--color--white);
    background-color: var(--color--black);
    border-radius: 5px;
    text-decoration: none;

    &:hover,
    &:focus {
        background-color: var(--color--red-dark);
        outline: 1px solid var(--color--red-dark);
        outline-offset: 2px;
    }

    &:active {
        background-color: var(--color--red);
        outline-color: var(--color--red);
    }
}

.CallToAction__text {
    display: block;

    & + .CallToAction__icon {
        margin-left: var(--spacing--micro);
    }
}

.CallToAction__icon {
    display: block;

    + .CallToAction__text {
        margin-left: var(--spacing--micro);
    }
}

.CallToAction__icon svg {
    fill: currentColor;
    width: auto;
    height: 1em;
    display: block;
}
