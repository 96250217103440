
.ContentQuotes {
    margin-bottom: calc(var(--spacing--giga) * -1);
    width: 100%;
}

.ContentQuotes__container {
    margin-bottom: var(--spacing--giga);
    font-size: var(--font-size--x-uno);
    font-weight: var(--font-weight--bold);
}

.ContentQuotes__quotes {
    padding-left: 0;
    list-style-type: none;
}

.ContentQuotes__icon {
    width: var(--quotes-icon);
    margin-bottom: var(--spacing--uno);

    & svg {
        fill: var(--color--red);
    }
}

@media(--breakpoint--medium) {

    .ContentQuotes__quotes {
        margin: 0 calc(var(--spacing--uno) * -1);
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }

    .ContentQuotes__container {
        padding: 0 var(--spacing--uno);
        width: 100%;

    }

    .ContentQuotes__text {
        max-width: 264px;
    }
}

@media(--breakpoint--large) {

    .ContentQuotes {
        margin: 0 var(--spacing--exa);
    }

    .ContentQuotes__quotes {
        margin: 0 calc(var(--spacing--mega) * -1);
        padding: 0 var(--spacing--exa) 0 calc(var(--spacing--exa) - (var(--quotes-icon) + var(--spacing--kilo)));
    }

    .ContentQuotes__container {
        display: flex;
        flex-direction: row;
        padding: 0 var(--spacing--mega);
        width: 100%;
    }

    .ContentQuotes__icon {
        margin: 0 var(--spacing--mega) 0 0;
    }

    .ContentQuotes__text {
        width: 100%;
    }
}
