.ContentType--accordeon {
    margin-bottom: var(--spacing--content-spacing);
    margin-left: auto;
    margin-right: auto;
    max-width: var(--content-max-width--accordeon);

}

.Accordeon__summary {
    background-color: var(--color--white);
    padding: var(--spacing--mega);

    &::marker {
        content: '';
        /* for Safari */
        color: transparent;
        font-size: 0;
    }
}

.Accordeon__summary-intro {
    font-weight: var(--font-weight--bold);
    line-height: var(--line-height--mega);
}


.Accordeon__summary-number {
    font-family: var(--font-family--crimson);
    font-size: var(--font-size--giga);
    font-weight: var(--font-weight--bold);
}

.Accordeon__summary-heading {
    margin-top: var(--spacing--micro);
    font-size: var(--spacing--mega);
    line-height: var(--line-height--kilo);
}

.Accordeon__content {
    margin-top: var(--spacing--milli);
    padding: var(--spacing--mega);
}

.Accordeon__summary-button {
    display: flex;
    font-size: var(--font-size--milli);
    font-size: var(--spacing--uno);
}

.Accordeon__summary-button-text {
    color: var(--color--red-dark);
    font-weight: var(--font-weight--bold);
}

.Accordeon__summary-button-icon {
    margin-top: auto;
    margin-left: var(--spacing--micro);
    transform: rotate(90deg);
    transition: .2s;

    [open] & {
        transform: rotate(270deg);
        transition: .2s;
    }

    & svg {
        fill: var(--color--red-dark);
        width: 1em;
        height: 1em;
    }
}


.Accordeon--color-white {
    & .Accordeon__summary {
        background-color: var(--color--white);
    }

    & .Accordeon__content {
        background-color: var(--color--white);
    }
}

.Accordeon--color-brown {
    & .Accordeon__summary {
        background-color: var(--color--beige-brown);
    }

    & .Accordeon__content {
        background-color: var(--color--beige-brown);
    }
}

.Accordeon--color-red {
    & .Accordeon__summary {
        background-color: var(--color--beige-red);
    }

    & .Accordeon__content {
        background-color: var(--color--beige-red);
    }
}

@media(--breakpoint--large) {
    .Accordeon__summary {
        display: flex;
        padding: var(--font-size--giga) var(--font-size--peta);
    }

    .Accordeon__summary-number {
        font-size: var(--font-size--tera);
        width: 60px;
        margin-right: var(--spacing--giga);
        flex-shrink: 0;
    }

    .Accordeon__summary-heading {
        font-size: var(--font-size--giga);
    }

    .Accordeon__summary-text {
        max-width: var(--accordeon-text-width);

        .Accordeon__summary-number + & {
            max-width: none;
        }
    }

    .Accordeon__summary-intro {
        font-size: var(--font-size--x-uno);
    }

    .Accordeon__summary-button-icon {
        margin-left: var(--spacing--uno);
    }

    .Accordeon__content-wrapper {
        max-width: var(--accordeon-text-width);
        margin: 0 auto;
    }
}
