
.js {
    .annotate {
        font-style: italic;
        color: #366ed4;
    }

    .listbox-area {
        padding: 20px;
        background: #eee;
        border: 1px solid #aaa;
        font-size: 0;
    }

    .left-area,
    .right-area {
        box-sizing: border-box;
        display: inline-block;
        font-size: 14px;
        vertical-align: top;
        width: 50%;
    }

    .left-area {
        padding-right: 10px;
    }

    .right-area {
        padding-left: 10px;
    }

    [role="listbox"] {
        __min-height: 18em;
        padding: 0;
        background: white;
        border: 1px solid #aaa;
    }

    [role="option"] {
        display: block;
        __padding: 0 1em 0 1.5em;
        position: relative;
        line-height: 1.8em;
    }

    [role="option"].focused {
        background-color: var(--color--red-dark);
        color: var(--color--white);

        & a {
            color: var(--color--white);
        }
    }

    __[role="option"][aria-selected="true"]::before {
        content: 'âœ“';
        position: absolute;
        left: 0.5em;
    }

    .move-right-btn {
        padding-right: 20px;
        position: relative;
    }

    .move-right-btn::after {
        content: ' ';
        height: 10px;
        width: 12px;
        background-image: url('../imgs/Arrows-Right-icon.png');
        background-position: center right;
        position: absolute;
        right: 2px;
        top: 6px;
    }

    .move-left-btn {
        padding-left: 20px;
        position: relative;
    }

    .move-left-btn::after {
        content: ' ';
        height: 10px;
        width: 12px;
        background-image: url('../imgs/Arrows-Left-icon.png');
        background-position: center left;
        position: absolute;
        left: 2px;
        top: 6px;
    }

    #ss_elem_list {
        max-height: 18em;
        overflow-y: auto;
        position: relative;
    }

    #exp_wrapper {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: flex-end;
        height: 100%;
    }

    #exp_button {
        border: none;
        border-radius: 0;
        font-size: 16px;
        text-align: left;
        padding: 5px 10px;
        __width: 150px;
        height: 100%;
        position: relative;
        background-color: transparent;
        display: inline-flex;
        align-items: center;

    }

    #__exp_button::after {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid var(--color--black);
        content: " ";
        position: absolute;
        right: 5px;
        top: 50%;
        transform :translateY(-50%);
    }

    #__exp_button[aria-expanded="true"]::after {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 0;
        border-bottom: 8px solid var(--color--black);
        content: " ";
        position: absolute;
        right: 5px;
        top: 50%;
        transform :translateY(-50%);
    }

    #exp_elem_list {
        border-top: 0;
        position: absolute;
        margin: 0;
        width: 148px;
        top: 100%;
        right: 0;
    }

    .hidden {
        display: none;
    }

    .toolbar {
        font-size: 0;
    }

    .toolbar-item {
        border: 1px solid #aaa;
        background: #ccc;
    }

    .toolbar-item[aria-disabled="false"]:focus {
        background-color: #eee;
    }

    .offscreen {
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        font-size: 14px;
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

.no-js {
    #exp_button {
        display: none;
    }

    #exp_elem_list {
        padding-left: 0;
        list-style-type: none;
        display: flex;
    }
}
