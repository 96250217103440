.DefList {
    & br {
        content: "";
        display: block;
        height: var(--spacing--nano);
    }
}

.DefList__section {
    background-color: var(--color--white);
    padding: var(--spacing--milli) var(--spacing--mega);
    margin-top: var(--spacing--case-list);
}

.DefList__section-heading {
    margin-bottom: 0;
    font-size: var(--font-size--x-uno);
    font-family: var(--font-family--roboto);
    text-transform: unset;
}

.DefList__list {
    margin: 0;
}

.DefList__subject {
    font-weight: var(--font-weight--bold);
    padding-top: var(--spacing--milli);
    margin-top: var(--spacing--milli);

    & ~ .DefList__subject {
        border-top: 1px solid var(--color--grey);
    }
}

.DefList__value {
    margin-top: var(--spacing--case-list);
    margin-left: var(--spacing--uno);

    & p:first-child {
        margin-top: 0;
    }

    & p:last-child {
        margin-bottom: 0;
    }
}

.DefList__value-list {
    margin: 0;
    padding-left: var(--spacing--kilo);
}

.DefList__sublist {
    margin: 0;
}

.DefList__sublist-subject {
    font-weight: var(--font-weight--bold);
    margin-top: var(--spacing--case-list);
}

.DefList__sublist-value {
    margin-left: 0;
    margin-top: var(--spacing--case-list);
}

@media(--breakpoint--large) {
    .DefList__section {
        display: flex;
    }

    .DefList__section-heading {
        max-width: 150px;
        margin-right: var(--spacing--uno);
        width: 100%;
    }

    .DefList__list {
        width: 100%;
        padding-right: var(--spacing--tera);
        display: grid;
        grid-template-columns: 33.333% 66.666%;
        margin-top: var(--spacing--micro);
    }

    .DefList__subject {
        margin-top: 0;
        padding-top: 0;
        padding-right: var(--spacing--milli);
        grid-column-start: 1;
        grid-column-end: 2;

        & ~ .DefList__subject {
            padding-top: var(--spacing--milli);
            margin-top: var(--spacing--milli);
        }
    }

    .DefList__value {
        grid-column-start: 2;
        grid-column-end: 3;
        margin: 0;
        padding-left: 120px;

        & ~ .DefList__value {
            padding-top: var(--spacing--milli);
            margin-top: var(--spacing--milli);
            border-top: 1px solid var(--color--grey);
        }

        & a {
            display: inline-block;
            margin-right: auto;
        }
    }

    .DefList__value--sublist {
        padding-left: 0;
    }

    .DefList__sublist {
        display: grid;
        grid-template-columns: 120px auto;
    }

    .DefList__sublist-subject {
        padding-right: var(--spacing--uno);
        grid-column-start: 1;
        grid-column-end: 2;
        margin-top: 0;

        & ~ .DefList__sublist-subject {
            margin-top: var(--spacing--case-list);
        }
    }

    .DefList__sublist-value {
        grid-column-start: 2;
        grid-clumn-end: 3;
        margin-top: 0;

        & ~ .DefList__sublist-value {
            margin-top: var(--spacing--case-list);
        }
    }
}
