.ContentCase__links {
    margin-top: var(--spacing--kilo);
    display: flex;
    flex-wrap: wrap;
}
@media(--breakpoint--medium--max) {
    .ContentCase__links {
        & .CallToAction {
            display: flex;
            margin-right: var(--spacing--mega);
        }
        & .CallToAction + .CallToAction {
            margin-top: var(--spacing--uno);
        }
    }

}
@media(--breakpoint--medium) {
    .ContentCase__links {
         & .CallToAction + .CallToAction {
             margin-left: var(--spacing--mega);
         }
     }
}


