.SecondaryNavigation * {
    box-sizing: border-box;
}

.SecondaryNavigation__list {
    display: flex;
    flex-wrap: wrap;
    __align-items: center;
    list-style: none;
    padding: 2px;
    margin: 0;
}

.SecondaryNavigation__item--language {
    position: relative;
    order: 1;
    z-index: var(--z-index--top);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        left: -100vw;
        background-color: var(--color--beige-extra-light);
    }

    & .SecondaryNavigation__icon:first-child {
        margin-right: var(--spacing--micro);
    }

    & .SecondaryNavigation__icon:last-child {
        margin-left: var(--spacing--micro);
    }
}

.SecondaryNavigation__item--simple {

    & .SecondaryNavigation__icon {
        &:first-child {
            margin-right: var(--spacing--micro);
        }
    }
}

.SecondaryNavigation__item--sign-language {
    flex-grow: 1;

    & .SecondaryNavigation__link {
        display: inline-flex;
    }

    & .SecondaryNavigation__icon {
        margin-right: var(--spacing--micro);
    }
}

.SecondaryNavigation__item--language-selector {
    flex-grow: 1;
}

.SecondaryNavigation__language-selector {
    padding: 0 var(--spacing--mega) 0 var(--spacing--nano);
    height: 100%;
}

.SecondaryNavigation__item--home {

    & .SecondaryNavigation__link {
        padding-left: var(--spacing--mega)
    }
}

.SecondaryNavigation__item--menu {
    margin-left: auto;
    text-align: right;
    text-transform: uppercase;

    & .SecondaryNavigation__link--button {
        padding-right: var(--spacing--mega);
        text-transform: uppercase;
    }
}

.SecondaryNavigation__item--languages {
    width: 100%;
    font-size: var(--font-size--micro);
}

.SecondaryNavigation__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: var(--spacing--uno);
    min-height: 44px;
    width: auto;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.SecondaryNavigation__icon {
    display: flex;

    & svg {
        height: var(--spacing--uno);
        width: var(--spacing--uno);
    }
}

.SecondaryNavigation__item--menu .SecondaryNavigation__link {
    display: flex;
    align-items: center;

    & .SecondaryNavigation__icon {
        margin-left: var(--spacing--micro);
    }
}

.SecondaryNavigation__button-span {
    display: flex;
    align-items: center;
}

/*
 * Toggle mechanism (with and without JS)
 */
.SecondaryNavigation__item--menu {

    & .SecondaryNavigation__link--close,
    & .SecondaryNavigation__button-span--close {
        display: none;
    }
}

.SecondaryNavigation__link--button[aria-expanded="true"] {

    & .SecondaryNavigation__button-span--close {
        display: flex;
    }

    & .SecondaryNavigation__button-span--open {
        display: none;
    }
}

#menu:target {

    & .SecondaryNavigation__link--open {
        display: none;
    }

    & .SecondaryNavigation__link--close {
        display: flex;
    }
}


@media(--breakpoint--xx-large--max) {

    .SecondaryNavigation__item:first-child {
        & .SecondaryNavigation__link {
            padding-left: var(--spacing--mega);
        }
    }

    .SecondaryNavigation__list {
        background-color: var(--color--beige-extra-light);
    }
}

@media(--breakpoint--small) {

    .SecondaryNavigation__list:after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
    }

    .SecondaryNavigation__item--languages {
        width: auto;
    }

    .SecondaryNavigation__item--language {
        margin-left: auto;
    }

}

@media(--breakpoint--xx-large) {
    .SecondaryNavigation {
        align-self: flex-end;
    }

    .SecondaryNavigation__list {
        border: none;
        position: relative;
        margin-bottom: -1rem;
    }

    .SecondaryNavigation__item {
        padding: 0;
    }

    .SecondaryNavigation__item--home,
    .SecondaryNavigation__item--menu {
        display: none;
    }

    .SecondaryNavigation__item--languages {
        background-color: transparent;
    }

    .SecondaryNavigation__item--language:before {
        display: none;
    }

    .SecondaryNavigation__item--sign-language {
        margin-right: calc(-1 * var(--spacing--milli));
    }

    .SecondaryNavigation__link {
        padding: 0 var(--spacing--milli);
    }

    .SecondaryNavigation__language-selector {
        padding: 0 var(--spacing--uno);
    }

    .SecondaryNavigation__item:last-child {
        & .SecondaryNavigation__link {
            padding: 0 var(--spacing--milli);
            margin-right: calc(0 - var(--spacing--milli) - 3px);
        }
    }

}
