.Toggletip__toggle {
    display: inline-flex;
    background-color: transparent;
    border: none;
    font-size: var(--font-size--uno);
    font-weight: var(--font-weight--bold);
    color: var(--color--red-dark);
    padding-left: 0;
    padding-right: 0;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}


.Toggletip__icon {
    margin-right: var(--spacing--nano);
    transition: .2s;

    & svg {
        width: .7em;
        height: .7em;
    }
}

.Toggletip__tip {
    display: none;
    font-size: var(--font-size--uno);
    font-style: italic;
    margin: var(--spacing--milli) 0 var(--spacing--milli) var(--spacing--uno);
    border-left: 1px solid var(--color--black);

    & a {
        word-break: break-all;
    }
}

.Toggletip__toggle[aria-expanded=true] {

    & .Toggletip__icon {
        transform: rotate(45deg);
        transition: .2s;
    }

    & + .Toggletip__tip {
        display: block;
    }
}

.Toggletip__line {
    display: block;
    padding-left: var(--spacing--uno);

    & + .Toggletip__line {
        margin-top: var(--spacing--micro);
    }
}
