.MainNavigation__sublist-wrapper {
    background-color: var(--color--beige-dark);

    .js & {
        display: none;
    }

    .js .MainNavigation__link--button[aria-expanded="true"] + & {
        display: block;
    }
}

.MainNavigation__sublist {
    list-style: none;
    __margin: 0 calc(-1 * var(--spacing--kilo));
    padding: var(--spacing--uno) var(--spacing--mega);
}

.MainNavigation__subitem--top {
    display: none;

    .js & {
        display: block;
    }
}

.MainNavigation__sublink {
    display: inline-block;
    text-decoration: none;
    padding: var(--spacing--milli) var(--spacing--uno);

    &[aria-current = page] {
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
        background-color: var(--color--white);
    }

    &:focus {
        outline-color: var(--color--black);
    }


    &:active {
        outline: none;
        background-color: transparent;
        text-decoration: none;
    }
}

@media(--breakpoint--xx-large) {

    .js .MainNavigation__sublist-wrapper {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;

        &:focus-within {
            display: block;
        }

        .js .MainNavigation__link--button[aria-expanded="true"] + & {
            display: block;
        }
    }

    .MainNavigation__sublist {
        display: flex;
        flex-wrap: wrap;
        max-width: var(--max-width);
        margin: 0 auto;
    }

    .MainNavigation__sublink {
        padding: var(--spacing--milli) var(--spacing--kilo);
    }

    .MainNavigation__subitem {
        padding: var(--spacing--micro) 0;
        margin: 0 var(--spacing--micro);
    }

    .MainNavigation__toggle {
        display: none;
    }
}
