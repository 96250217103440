.spkbl + .spkbl {
    margin-top: var(--spacing--uno);
}

.spkbl-player {
    & * {
        font-family: var(--font-family--roboto);
    }

    margin-bottom:  var(--spacing--uno);

    & .spkbl-ctrl {
        font-size: var(--font-size--uno);
        font-weight: var(--font-weight--bold);
        color: var(--color--black);
        background-color: transparent;
        border: none;
        min-height: 2em;
        display: inline-flex;
        align-items: center;

        &:before {
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            margin-right: var(--spacing--micro);
        }

        &[aria-pressed=true] {
            background-color: transparent;
        }
    }


    & .spkbl-ctrl--progress {
        z-index: unset;
        border: 1px solid var(--color--black);
        height: 0.75rem;
        width: 4em;
        min-height: unset;
        margin: 0 var(--spacing--milli);
        border-radius: 10px;
        padding: 0;

        &::before {
            display: none;
        }

        &::-webkit-progress-bar {
            __background-color: var(--color--grey);
            background-color: transparent;
        }

        &::-moz-progress-bar {
            background-color: var(--color--grey--dark) !important;
            border-radius: 10px;
        }

        &::-webkit-progress-value {
            background-color: var(--color--grey--dark);
            border-radius: 10px;
        }
    }


    & .spkbl-ctrl--play:before {
        content: url("../graphics/read.svg");
        width: 1.7em;
    }

    & .spkbl-ctrl--pause:before {
        content: url("../graphics/pause.svg");
    }

    & .spkbl-ctrl--pause[aria-pressed=true]:before {
        content: url("../graphics/play.svg");
    }

    & .spkbl-ctrl--stop:before {
        content: url("../graphics/stop.svg");
    }
}

@media(--breakpoint--large) {
    .spkbl-player {
        & .spkbl-ctrl {
            &:before {
                margin-right: var(--spacing--uno);
            }
        }
        & .spkbl-ctrl--progress {
            width: 8rem;
            margin: 0 var(--spacing--mega);
        }
    }
}
