.LanguageNavigation {
    position: relative;
    display: flex;
    align-items: center;
}

.LanguageNavigation__icon {
    margin-right: var(--spacing--micro);
}

.LanguageNavigation__icon svg {
    max-height: 1rem;
    max-width: 1rem;
    display: block;
}

.LanguageNavigation__button {
    border: none;
    background-color: var(--color--beige-extra-light);
    display: block;
    margin-left: auto;
    padding: var(--spacing--nano) var(--spacing--micro);

    & svg {
        transition: transform 0.2s;
    }

    &[aria-expanded = true],
    &:hover {
        & + .LanguageNavigation__list {
            display: block;
        }

        & svg {
            transform: rotate(180deg);
            transition: transform 0.2s;
        }
    }
}

.LanguageNavigation__text {
    font-size: var(--font-size--micro);
    font-family: var(--font-family--roboto);
}

.LanguageNavigation__placeholder {
    width: var(--language-width);
    min-height: 52px;
}

.LanguageNavigation__list {
    box-sizing: border-box;
    list-style-type: none;
    background-color: var(--color--beige-extra-light);
    padding: 0;

    &:hover,
    &:focus-within {
        position: absolute;
        left: calc(1rem + var(--spacing--nano));

        & .LanguageNavigation__link-icon {
            display: inline;
        }

        & .LanguageNavigation__item {
            display: block;
        }

        & .LanguageNavigation__item--current svg {
            fill: var(--color--black);
        }

        & .LanguageNavigation__link-icon--dropdown {
            display: none;
        }
    }
}

.LanguageNavigation__item {
    display: none;
    width: var(--language-width);
}

.LanguageNavigation__item--current {
    display: block;
}

.LanguageNavigation__link {
    padding: var(--spacing--uno) var(--spacing--micro);
    display: flex;
    align-items: center;
    text-decoration: none;
    min-height: 44px;

    &:focus,
    &:hover {
        background-color: var(--color--red-dark);
        color: var(--color--white);

        & svg {
            fill: var(--color--white);
        }
    }
}

.LanguageNavigation__item--current .LanguageNavigation__link {
    &:focus,
    &:hover {
        & svg {
            fill: var(--color--white);
        }
    }
}


.LanguageNavigation__link-icon {
    margin-left: var(--spacing--nano);

    & svg {
        width: .8em;
        height: .8em;
        fill: transparent;
    }
}

.LanguageNavigation__link-icon--dropdown {
    margin-left: auto;

    & svg {
        display: block;
        width: .6rem;
        height: 1rem;
    }
}

.js {
    & .LanguageNavigation__link {
        pointer-events: none;
    }
}

@media(--breakpoint--xx-large--max) {
    .LanguageNavigation {
        margin-left: auto;
    }
}

@media(--breakpoint--xx-large) {
    .LanguageNavigation {
        height: auto;
    }
    .LanguageNavigation__button {
        background-color: transparent;
    }

    .LanguageNavigation__list {
        background-color: var(--color--white);
        padding: 0;
    }
}
